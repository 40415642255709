<template>
  <div>
    <Grid
      :key="key"
      :service="service"
      route_name="dynamic_report_executions">
      <template v-slot:gridThead>
        <vs-th key="id" sort-key="id">ID</vs-th>
        <vs-th key="name" sort-key="name">{{ $t('relatorios_dinamicos_grid_nome') }}</vs-th>
        <vs-th key="description" sort-key="description">{{ $t('relatorios_dinamicos_grid_descricao') }}</vs-th>
        <vs-th key="description" sort-key="description">{{ $t('status') }}</vs-th>
        <vs-th key="description" sort-key="description">{{ $t('relatorios_dinamicos_grid_solicitado_em') }}</vs-th>
      </template>
      <template v-slot:gridData="props">
        <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in props.data">
          <vs-td width="5%" key="id">
            <div> {{ tr.id }} </div>
          </vs-td>
          <vs-td width="25%" key="name">
            <span> {{ tr.dynamic_report.name }}</span>
          </vs-td>
          <vs-td width="30%" key="description">
            <span> {{ tr.dynamic_report.description }}</span>
          </vs-td>
          <vs-td width="10%" key="description">
            <span> {{ tr.status }}</span>
          </vs-td>
          <vs-td width="10%" key="description">
            <span> {{$utils.format.date(tr.created_at)}} </span>
          </vs-td>
          <vs-td width="10%">
            <span class="flex flex-row justify-center gap-1">
              <feather-icon
                icon="DownloadIcon"
                svgClasses="h-5 w-5 mb-1 mr-3 stroke-current text-primary"
                @click="download(tr)"
                v-tooltip.top="{content: 'Download', delay: { show: 1000, hide: 100 }}"/>
            </span>
          </vs-td>
        </vs-tr>
      </template>
    </Grid>
  </div>
</template>

<script>
import DynamicReportExecutionService from '@/services/api/DynamicReportExecutionService'
import FileDownload from 'js-file-download'
import MediaService from '@/services/api/MediaService'

export default {
  data: () => ({
    service: null,
    key: 0,
    mediaService: null
  }),
  methods: {
    async download(dynamicReport) {
      this.$vs.loading()
      this.mediaService.downloadMedia(dynamicReport.file.id, dynamicReport.file.file_name, dynamicReport.file.mime_type).then(
        (success) => {
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('messages.success'))
        },
        (error) => {
          this.$vs.loading.close()
          this.notifySuccess(this.$vs, this.$t('messages.error'))
        },
      )
    }
  },
  beforeMount() {
    this.service = DynamicReportExecutionService.build(this.$vs)
    this.mediaService = MediaService.build(this.$vs)
  }
}
</script>
