var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("Grid", {
        key: _vm.key,
        attrs: {
          service: _vm.service,
          route_name: "dynamic_report_executions",
        },
        scopedSlots: _vm._u([
          {
            key: "gridThead",
            fn: function () {
              return [
                _c("vs-th", { key: "id", attrs: { "sort-key": "id" } }, [
                  _vm._v("ID"),
                ]),
                _c("vs-th", { key: "name", attrs: { "sort-key": "name" } }, [
                  _vm._v(_vm._s(_vm.$t("relatorios_dinamicos_grid_nome"))),
                ]),
                _c(
                  "vs-th",
                  { key: "description", attrs: { "sort-key": "description" } },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("relatorios_dinamicos_grid_descricao"))
                    ),
                  ]
                ),
                _c(
                  "vs-th",
                  { key: "description", attrs: { "sort-key": "description" } },
                  [_vm._v(_vm._s(_vm.$t("status")))]
                ),
                _c(
                  "vs-th",
                  { key: "description", attrs: { "sort-key": "description" } },
                  [
                    _vm._v(
                      _vm._s(_vm.$t("relatorios_dinamicos_grid_solicitado_em"))
                    ),
                  ]
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "gridData",
            fn: function (props) {
              return _vm._l(props.data, function (tr, indextr) {
                return _c(
                  "vs-tr",
                  { key: indextr, attrs: { data: tr } },
                  [
                    _c("vs-td", { key: "id", attrs: { width: "5%" } }, [
                      _c("div", [_vm._v(" " + _vm._s(tr.id) + " ")]),
                    ]),
                    _c("vs-td", { key: "name", attrs: { width: "25%" } }, [
                      _c("span", [
                        _vm._v(" " + _vm._s(tr.dynamic_report.name)),
                      ]),
                    ]),
                    _c(
                      "vs-td",
                      { key: "description", attrs: { width: "30%" } },
                      [
                        _c("span", [
                          _vm._v(" " + _vm._s(tr.dynamic_report.description)),
                        ]),
                      ]
                    ),
                    _c(
                      "vs-td",
                      { key: "description", attrs: { width: "10%" } },
                      [_c("span", [_vm._v(" " + _vm._s(tr.status))])]
                    ),
                    _c(
                      "vs-td",
                      { key: "description", attrs: { width: "10%" } },
                      [
                        _c("span", [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$utils.format.date(tr.created_at)) +
                              " "
                          ),
                        ]),
                      ]
                    ),
                    _c("vs-td", { attrs: { width: "10%" } }, [
                      _c(
                        "span",
                        { staticClass: "flex flex-row justify-center gap-1" },
                        [
                          _c("feather-icon", {
                            directives: [
                              {
                                name: "tooltip",
                                rawName: "v-tooltip.top",
                                value: {
                                  content: "Download",
                                  delay: { show: 1000, hide: 100 },
                                },
                                expression:
                                  "{content: 'Download', delay: { show: 1000, hide: 100 }}",
                                modifiers: { top: true },
                              },
                            ],
                            attrs: {
                              icon: "DownloadIcon",
                              svgClasses:
                                "h-5 w-5 mb-1 mr-3 stroke-current text-primary",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.download(tr)
                              },
                            },
                          }),
                        ],
                        1
                      ),
                    ]),
                  ],
                  1
                )
              })
            },
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }